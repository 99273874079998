<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div
      class="d-flex justify-content-between align-items-center"
      slot="header"
    >
      <h5>{{ $route.meta.title }}</h5>
      <div class="d-flex justify-content-end align-items-center">
        <el-input
          placeholder="关键词搜索"
          size="small"
          v-model="params.keyword"
        >
        </el-input>
      </div>
    </div>

    <div v-loading="loading">
      <el-table :data="lists">
        <el-table-column label="视频" width="120">
          <template slot-scope="scope">
            <router-link
              :to="'/video/detail/' + scope.row.video_id"
              target="_blank"
            >
              <img
                v-if="scope.row.video.thumbData"
                :src="viewThumb(scope.row.video.thumbData.thumb, 'small')"
                width="120"
            /></router-link>
          </template>
        </el-table-column>
        <el-table-column prop="title">
          <template slot-scope="scope">
            <router-link
              :to="'/video/detail/' + scope.row.video_id"
              target="_blank"
            >
              {{ scope.row.video.title }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="用户">
          <template slot-scope="scope">
            <NickName v-model="scope.row.video.user" /> </template
        ></el-table-column>

        <el-table-column label="频道">
          <template slot-scope="scope">
            {{ scope.row.video.category ? scope.row.video.category.name : "" }}
          </template></el-table-column
        >

        <el-table-column prop="createTime" label="日期" width="180" sortable>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-close"
              size="mini"
              @click="del(scope.row, scope.$index)"
              >删除</el-button
            >
          </template></el-table-column
        >
      </el-table>
    </div>

    <div class="text-center mt-20" v-if="params.pages > 1">
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="params.page"
        :page-size="params.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      loading: false,
      category: [],
      lists: [],
      searchTimer: null, //搜索计时器
      params: {
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    this.params.category_id = this.$route.meta.category_id;
    this.detailTag = this.$route.meta.detailTag;
    this.getList();
  },
  mounted() {},
  methods: {
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.params.user_id = this.user.id;
      this.$api.ucenter.Video.favorite(this.params)
        .then((res) => {
          this.loading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },

    del(item, i) {
      this.$utils.confirm(
        `确定要删除"${item.title}吗？本操纵不可逆，请谨慎操作"`,
        () => {
          this.$api.video.Video.favorite({ id: item.video_id })
            .then((res) => {
              this.$delete(this.lists, i);
              this.params.total--;
              if (this.lists.length < 1) {
                this.getList();
              }
              this.$utils.success("删除成功");
            })
            .catch(() => {
              this.loading = false;
            });
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  vertical-align: top;
}
</style>